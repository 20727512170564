import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, CustomAutocomplete, Dialog, Select, Text, Time } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { accountService, inHomeService } from 'services';

import validationSchema from './validationSchema';

export const DialogNewTechnician = ({
  openDialogNewTechnician,
  HandleCloseDialogNewTechincian,
}) => {
  if (!openDialogNewTechnician) return null;

  const [isLoading, setIsLoading] = useState(false);
  const [typeProducts, setTypeProducts] = useState([]);

  const optionsExtra = [
    { label: 'Sim', value: 'Sim' },
    { label: 'Não', value: 'Não' },
  ];

  const optionsTypeTec = [
    { label: 'Motorista', value: 'Motorista' },
    { label: 'Reparador', value: 'Reparador' },
  ];

  const [users, , ,] = useQuery(() => accountService.listAllUsers(), []);

  const optionsUsers = (users || [])?.map((a) => ({
    value: a.id,
    label: a.name,
    ativo: a.active,
  }));

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await inHomeService.findTypeProductsPdv();
      setTypeProducts(data);
    };
    fetchData();
  }, []);

  const handleCreateNewTechincian = async (values) => {
    setIsLoading(true);

    try {
      const { data } = await inHomeService.createTechnician({
        technician: values.input_tec.value,
        jornada: values.jornada,
        extra: values.hours_extra,
        cellphone: values.cellphone,
        typeProducts: values.type_products,
        type_tec: values.type_tec,
      });
      toast.success('Técnico criado com sucesso.');
      HandleCloseDialogNewTechincian();
      setIsLoading(false);
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro ao registrar técnico');
      setIsLoading(false);
    }
  };

  console.log(optionsUsers);
  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogNewTechnician}>
        <Dialog.Title onClose={HandleCloseDialogNewTechincian}>Novo Técnico</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_tec: '',
              hours_extra: '',
              cellphone: '',
              jornada: '',
              type_tec: '',
              type_products: [],
            }}
            onSubmit={handleCreateNewTechincian}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <CustomAutocomplete
                      label='Técnico'
                      name='input_tec'
                      filteredValues={optionsUsers || []}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      value={props.values.input_tec ? props.values.input_tec.label : ''}
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      size='small'
                      name='cellphone'
                      label='Celular'
                      component={Text}
                      variant='outlined'
                      type='text'
                      fullWidth
                      mask='phone'
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      size='small'
                      label='Tipo de Técnico'
                      name='type_tec'
                      component={Select}
                      variant='outlined'
                      options={optionsTypeTec}
                      type='text'
                      fullWidth
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      size='small'
                      label='Tipo de produto'
                      name='type_products'
                      component={Select}
                      variant='outlined'
                      options={typeProducts}
                      type='text'
                      fullWidth
                      loading={isLoading}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      size='small'
                      name='jornada'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='Jornada'
                      component={Time}
                      defaultValue='00:00'
                      InputLabelProps={{ shrink: true }}
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      size='small'
                      name='hours_extra'
                      label='Extra'
                      component={Select}
                      variant='outlined'
                      options={optionsExtra}
                      type='text'
                      fullWidth
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Button
                      type='submit'
                      endIcon={<CheckCircleIcon />}
                      fullWidth
                      loading={isLoading}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
